"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OKTA_LOGIN_WIDGET = exports.NO_ACCOUNT_MESSAGES = exports.MY_ACCOUNT_MESSAGES = exports.SHARED_MESSAGES = exports.LOGIN_MESSAGES = exports.MESSAGES = void 0;
exports.MESSAGES = {
    completeSetUp: 'Complete setup',
    hasAccount: 'Already have an account?',
    headingExpiredAccount: 'Your invitation has expired',
    headingSetUp: 'Set up your account',
    headerContentExpiredAccount: 'You can request a new account activation link by reaching out to your account admin. Remember that activation links are only valid for 24 hours.',
    login: 'Log in',
    passwordLengthErr: 'Password must be minimum of 8 characters',
    headingLogin: 'Log in to your account',
    personalInfoTitle: 'Personal information',
    changePasswordTitle: 'Change password',
    editProfileTitle: 'Edit Profile',
    logOutTitle: 'Logout',
};
exports.LOGIN_MESSAGES = {
    invalidEmailError: 'Email is invalid. Please try again',
    invalidPasswordError: 'Password is invalid. Please try again',
    passwordRequiredError: 'Password is required field',
    emailRequiredError: 'Email is required field',
    passwordLengthRequired: 'Password must be minimum of 8 characters',
};
exports.SHARED_MESSAGES = {
    forgotPassword: 'Forgot password?',
    forgotYourPasswordTitle: 'Forgot your password?',
    checkYourEmailTitle: 'Check your Email!',
    enterYourEmailSubTitle: 'Enter your email address below and we will send you the password reset link.',
    invalidEmailError: 'Email is invalid. Please try again',
    accountIdShouldBeNumberError: 'Account ID should be number. Please try again',
    invalidAccountIdError: 'Account ID is invalid. Please try again',
    emailRequiredError: 'Email is required field',
    accountIdRequiredError: 'Account ID is required field',
    returnToLogin: 'Return to Log in',
    requestResetLink: 'Request reset link',
    accountIdText: 'accountId',
    emailText: 'email',
    idV2Switch: "Don't have an account ID?",
    idV2SwitchLink: 'Click here',
};
exports.MY_ACCOUNT_MESSAGES = {
    firstNameRequiredError: 'First name is required field',
    lastNameRequiredError: 'Last name is required field',
    firstNameExceedError: 'First name cannot exceed 40 characters',
    lastNameExceedError: 'Last name cannot exceed 40 characters',
    firstNameText: 'First name',
    lastNameText: 'Last name',
    emailText: 'Email',
};
exports.NO_ACCOUNT_MESSAGES = {
    mainMessage: "You do not have access to any of the fabric's Applications",
    footerText: 'Got questions?',
    footerLinkText: 'Reach out to support team',
};
exports.OKTA_LOGIN_WIDGET = {
    title: 'Welcome to fabric!',
    username: 'Email',
    password: 'Password',
    passwordExpired: 'Set a new password',
    emailVerifyTitle: 'Email verification required',
    rememberOnDevice: 'Remember me in this device for the next {0}',
    sendVerificationEmail: 'Email verification will be sent to {0}',
    usernameError: 'Please enter an Email',
    passwordError: 'Please enter a password',
    apiError: 'Sign in failed!',
};
