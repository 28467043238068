"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var theme = {
    breakpoints: {
        keys: ['md', 'lg'],
        values: {
            md: 1024,
            lg: 1440,
        },
    },
    direction: 'ltr',
    palette: {
        common: {
            black: '#0C053D', // For body paragraph text, headers and subtitles
            white: '#fff',
        },
        blue: {
            main: '#374CCC',
            electric: '#58687A',
        },
        text: {
            main: '#0C053D', // For body paragraph text, headers and subtitles
            theme: '#0A083A', // For body paragraph text, headers and subtitles
            label: '#58687A', // Text Field Labels”, “Table header text
            enable: '#8994A3', // Side navigation links that are enabled
            outline: '#C4C9D1', // Text Field outlines
            separation: '#E6E8EB',
            container: '#F3F3F5',
            disabled: 'rgba(0, 0, 0, 0.38)',
        },
        backdrop: {
            main: 'rgba(0, 0, 0, 0.35)',
        },
        accent: {
            main: '#e74563', // pink
            dark: '#e6e6eb', // border-line
        },
        success: {
            light: '#e0f7e9',
            lighter: '#d1f3df',
            main: '#3BC77A',
            dark: '#39b86f',
        },
        error: {
            light: '#ffdbd8',
            lighter: '#ffe7e5',
            main: '#F24659',
            dark: '#e56154',
            darker: '#b10000',
        },
        warn: {
            light: '#fff0d5',
            lighter: '#fff4e3',
            main: '#E5CA11',
            dark: '#ce821b',
        },
        grey: {
            light: '#e9e9ea',
            lighter: '#f6f6f6',
            main: '#bfbfbf',
            dark: '#929292',
        },
        black: {
            main: '#0C053D',
            contrastText: '#fff',
        },
        white: {
            main: '#fff',
            contrastText: '#0C053D',
        },
    },
    shadows: [],
    typography: {
        fontFamily: 'Gilroy',
        fontSize: 14,
        h1: {
            fontSize: '48px',
            fontWeight: '800',
            letterSpacing: 'normal',
            lineHeight: '56px',
            fontStyle: 'normal',
        },
        h2: {
            fontSize: '36px',
            fontWeight: '600',
            letterSpacing: 'normal',
            lineHeight: '42px',
            fontStyle: 'normal',
        },
        h3: {
            fontSize: '24px',
            fontWeight: 'bold',
            letterSpacing: 'normal',
            lineHeight: '32px',
            fontStyle: 'normal',
        },
        h4: {
            fontSize: '18px',
            fontWeight: '500',
            letterSpacing: 'normal',
            lineHeight: '24px',
            fontStyle: 'normal',
        },
        h5: {
            fontSize: '14px',
            fontWeight: '500',
            letterSpacing: 'normal',
            lineHeight: '20px',
            fontStyle: 'normal',
        },
        h6: {
            fontSize: '12px',
            fontWeight: '600',
            letterSpacing: '0.5px',
            lineHeight: '16px',
            fontStyle: 'normal',
        },
        bodyLarge: {
            fontSize: '16px',
            fontWeight: '500',
            letterSpacing: 'normal',
            lineHeight: '24px',
            fontStyle: 'normal',
            lineSpacing: '12px',
        },
        body: {
            fontSize: '14px',
            fontWeight: '500',
            letterSpacing: 'normal',
            lineHeight: '20px',
            fontStyle: 'normal',
            lineSpacing: '12px',
        },
        link: {
            fontSize: '14px',
            fontWeight: '600',
            letterSpacing: 'normal',
            lineHeight: '20px',
            fontStyle: 'normal',
        },
        label: {
            fontSize: '12px',
            fontWeight: '600',
            letterSpacing: 'normal',
            lineHeight: '16px',
            fontStyle: 'normal',
        },
        small: {
            fontSize: '12px',
            fontWeight: '500',
            letterSpacing: 'normal',
            lineHeight: '16px',
            fontStyle: 'normal',
        },
    },
    transitions: {
        easing: {
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            standard: 300,
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195,
        },
    },
    zIndex: {
        hoverOver: 1000,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
};
exports.default = theme;
