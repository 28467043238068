import React from 'react';
export default props => (
  <svg
    width='190'
    height='49'
    viewBox='0 0 190 49'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <ellipse cx='42.8789' cy='24.1191' rx='3.21583' ry='3.21583' fill='white' />
    <ellipse cx='42.8789' cy='42.877' rx='3.21583' ry='3.21583' fill='white' />
    <ellipse cx='24.1191' cy='42.877' rx='3.21583' ry='3.21583' fill='white' />
    <ellipse cx='24.12' cy='24.1192' rx='5.3597' ry='5.35971' fill='white' />
    <ellipse cx='5.35971' cy='5.35971' rx='5.35971' ry='5.35971' fill='white' />
    <ellipse cx='5.35971' cy='24.1192' rx='5.35971' ry='5.35971' fill='white' />
    <ellipse cx='24.12' cy='5.35971' rx='5.3597' ry='5.35971' fill='white' />
    <ellipse cx='42.8797' cy='5.35971' rx='5.35973' ry='5.35971' fill='white' />
    <ellipse cx='5.35971' cy='42.877' rx='5.35971' ry='5.35971' fill='white' />
    <path
      d='M98.9038 16.4935V21.0064C96.8166 17.8054 93.4772 15.8113 89.042 15.8113C81.7892 15.8113 75.8408 21.846 75.8408 29.6125C75.8408 37.379 81.7892 43.4138 89.042 43.4138C93.4772 43.4138 96.8166 41.4197 98.9038 38.2186V42.7316H103.443V16.4935H98.9038ZM89.616 39.0058C84.3981 39.0058 80.3804 34.9651 80.3804 29.6125C80.3804 24.26 84.3981 20.2193 89.616 20.2193C94.886 20.2193 98.9038 24.26 98.9038 29.6125C98.9038 34.9651 94.886 39.0058 89.616 39.0058Z'
      fill='white'
    />
    <path
      d='M122.67 16.004C118.235 16.004 114.895 18.0035 112.808 21.2133V6.16406H108.269V42.998H112.808V38.4727C114.895 41.6825 118.235 43.682 122.67 43.682C129.923 43.682 135.871 37.6308 135.871 29.843C135.871 22.0553 129.923 16.004 122.67 16.004ZM122.096 39.262C116.826 39.262 112.808 35.2102 112.808 29.843C112.808 24.4758 116.826 20.4241 122.096 20.4241C127.314 20.4241 131.332 24.4758 131.332 29.843C131.332 35.2102 127.314 39.262 122.096 39.262Z'
      fill='white'
    />
    <path
      d='M143.96 20.9753V16.5527H139.354V42.8774H143.96V28.9253C143.96 22.7127 148.248 20.712 152.218 20.87V16.0789C148.883 16.0789 145.654 17.2898 143.96 20.9753Z'
      fill='white'
    />
    <path d='M160.525 42.8783H156.237V16.6157H160.525V42.8783Z' fill='white' />
    <path
      d='M178.262 43.6818C183.375 43.6818 187.809 40.9265 190 36.7936L186.14 34.5152C184.679 37.2705 181.81 39.125 178.262 39.125C173.045 39.125 169.08 35.0451 169.08 29.7465C169.08 24.395 173.045 20.3151 178.262 20.3151C181.757 20.3151 184.679 22.1696 185.983 24.9778L189.791 22.7524C187.809 18.5665 183.427 15.8113 178.262 15.8113C170.384 15.8113 164.542 21.9046 164.542 29.7465C164.542 37.5884 170.384 43.6818 178.262 43.6818Z'
      fill='white'
    />
    <path
      d='M74.7692 9.82086V5.40628C67.2073 4.88074 63.1884 8.82232 63.1884 15.8121V16.6004H58.958V21.015H63.1884V42.8776H67.789V21.015H74.7692V16.6004H67.789V15.8121C67.789 11.2398 70.1686 9.45298 74.7692 9.82086Z'
      fill='white'
    />
    <ellipse cx='158.382' cy='8.84392' rx='2.67986' ry='2.67986' fill='white' />
  </svg>
);
