"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var ds4_beta_1 = require("ds4-beta");
var react_router_dom_1 = require("react-router-dom");
var messages_1 = require("../../commons/messages");
var constants_1 = require("../../commons/constants");
var helpers_1 = require("../../commons/helpers");
var styles_1 = require("./styles");
var proxy_client_react_1 = require("@unleash/proxy-client-react");
var isBrowser = typeof window !== 'undefined';
var DS4NoAccount = function () {
    return (react_1.default.createElement(ds4_beta_1.EmptyPage, { headerText: messages_1.NO_ACCOUNT_MESSAGES.mainMessage, footerContent: react_1.default.createElement(styles_1.StyledFooterContainer, null,
            messages_1.NO_ACCOUNT_MESSAGES.footerText,
            ' ',
            react_1.default.createElement("a", { href: 'https://support.fabric.inc/hc/en-us/requests/new' }, messages_1.NO_ACCOUNT_MESSAGES.footerLinkText)) }));
};
var NoAccount = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var ds4 = (0, proxy_client_react_1.useFlag)(constants_1.COPILOT_AUTHENTICATION_DS4);
    var userId = (0, react_1.useMemo)(function () { return (0, helpers_1.getByKeyFromSessionStorage)(isBrowser, 'user'); }, []);
    var accounts = (0, react_1.useMemo)(function () { return (0, helpers_1.getByKeyFromSessionStorage)(isBrowser, 'accounts'); }, []);
    if (!userId) {
        // logged out state, goto login page
        navigate(constants_1.NAV_LOGIN_V2, { replace: true });
    }
    // user has access to fabric apps, goto activity page
    if (accounts) {
        navigate(constants_1.HOME_ACTIVITY, { replace: true });
    }
    // if ds4 enabled then return DS4 component
    if (ds4) {
        return react_1.default.createElement(DS4NoAccount, null);
    }
    return (
    // user doesn't have access to fabric apps
    react_1.default.createElement(styles_1.StyledContainer, null,
        react_1.default.createElement("div", { className: 'mainMessage' }, messages_1.NO_ACCOUNT_MESSAGES.mainMessage),
        react_1.default.createElement("div", { className: 'footerText' },
            messages_1.NO_ACCOUNT_MESSAGES.footerText,
            react_1.default.createElement("a", { className: 'footerLink', href: 'https://support.fabric.inc/hc/en-us/requests/new' }, messages_1.NO_ACCOUNT_MESSAGES.footerLinkText))));
};
exports.default = NoAccount;
