"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oktaConfig = void 0;
var messages_1 = require("../../commons/messages");
exports.oktaConfig = {
    issuer: process.env.OKTA_ISSUER,
    clientId: process.env.OKTA_CLIENT_ID,
    redirectUri: "".concat(window.location.origin, "/auth/v2/login"),
    pkce: true,
    authParams: {
        responseType: ['token', 'id_token', 'code'],
        scopes: [
            'openid',
            'email',
            'profile',
            'address',
            'phone',
            'offline_access',
        ],
        pkce: true,
    },
    baseUrl: 'https://fabric-copilot.okta.com',
    features: {
        // Used to enable registration feature on the widget.
        // https://github.com/okta/okta-signin-widget#feature-flags
        idpDiscovery: true,
    },
    idpDiscovery: {
        requestContext: "".concat(window.location.origin, "/auth/v2/login"),
    },
    tokenManager: {
        storage: 'sessionStorage',
    },
    i18n: {
        en: {
            'primaryauth.title': messages_1.OKTA_LOGIN_WIDGET.title,
            'primaryauth.username.placeholder': messages_1.OKTA_LOGIN_WIDGET.username,
            'primaryauth.username.tooltip': '',
            'primaryauth.password.placeholder': messages_1.OKTA_LOGIN_WIDGET.password,
            'primaryauth.password.tooltip': '',
            'password.forgot.email.or.username.placeholder': messages_1.OKTA_LOGIN_WIDGET.username,
            'password.forgot.email.or.username.tooltip': messages_1.OKTA_LOGIN_WIDGET.username,
            'password.expired.title.generic': messages_1.OKTA_LOGIN_WIDGET.passwordExpired,
            'rememberDevice.timebased': messages_1.OKTA_LOGIN_WIDGET.rememberOnDevice,
            'email.mfa.title': messages_1.OKTA_LOGIN_WIDGET.emailVerifyTitle,
            'email.mfa.description': messages_1.OKTA_LOGIN_WIDGET.sendVerificationEmail,
            'error.username.required': messages_1.OKTA_LOGIN_WIDGET.usernameError,
            'error.password.required': messages_1.OKTA_LOGIN_WIDGET.passwordError,
            'errors.E0000004': messages_1.OKTA_LOGIN_WIDGET.apiError,
        },
    },
};
