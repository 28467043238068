"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNavLinks = void 0;
var constants_1 = require("../../commons/constants");
var getNavLinks = function (isNewTerminologyEnabled) {
    return [
        {
            id: 1,
            label: 'My Profile',
            url: '/auth/my-account',
            active: true,
        },
        {
            id: 2,
            label: 'Manage Users',
            url: isNewTerminologyEnabled ? constants_1.HOME_USER_LIST : constants_1.ADMIN_USER_LIST,
        },
        // {
        //   id: 3,
        //   label: "Roles & Permissions",
        //   url: "/",
        //   disabled: true,
        // },
    ];
};
exports.getNavLinks = getNavLinks;
